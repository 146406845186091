import React from 'react'
import { Link, graphql } from 'gatsby'

import Bio from '../components/bio'
import Layout from '../components/layout'
import SEO from '../components/seo'

class About extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <div className="colophon">
          <div className="colophon__color1__base"/>
          <div className="colophon__color1__dark"/>
          <div className="colophon__color1__darker"/>
          <div className="colophon__color1__darkest"/>
        </div>
        <div className="colophon">
          <div className="colophon__color2__base"/>
          <div className="colophon__color2__dark"/>
          <div className="colophon__color2__darker"/>
          <div className="colophon__color2__darkest"/>
        </div>
      </Layout>
    )
  }
}

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

